import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { supabase } from "supabase/supabaseClient";
import logo from "assets/matrimony_logo.png";
import SearchBar from "./SearchBar";
import TranslationDropdown from "./TranslationDropdown";

import { useTranslation } from "react-i18next";
import { sendLogToServer } from "api/logging";
import { useSelector } from "react-redux";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;

const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Team", href: "#", current: false },
  { name: "Projects", href: "#", current: false },
  { name: "Calendar", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavbarComponent({ searchBarOn, textInsteadSearchbar }) {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userData);

  // useState to open logout option
  const [openLogout, setOpenLogout] = useState(false);

  const navigate = useNavigate();

  const handleLogout = async () => {
    navigate("/");

    try {
      await supabase.auth.signOut().then(() => {
        alert("Logout Successfull");
      });
    } catch (error) {
      sendLogToServer("Error logging out:", error.message);
    }
  };

  var imageSrc = "";

  if (localStorage.getItem("userFirstImage") === "null") {
    imageSrc = "https://via.placeholder.com/68x68";
  } else {
    imageSrc = `${SUPABASE_URL}/storage/v1/object/public/avatars/${
      userData.data.id
    }/${localStorage.getItem("userFirstImage")}`;
  }

  return (
    <Disclosure as="nav" className="bg-white px-4 h-full w-full">
      {({ open, close }) => (
        <>
          <div className=" h-full w-full px-2 sm:px-6 lg:px-8 relative flex items-center justify-between">
            <div className="flex mr-7  items-center justify-center sm:items-stretch sm:justify-start">
              <div
                onClick={() => {
                  navigate("/home");
                }}
                className="flex flex-shrink-0 items-center "
              >
                <img className="h-8 w-auto" src={logo} alt="Your Company" />
              </div>
            </div>

            {searchBarOn ? (
              <SearchBar />
            ) : (
              <div className="w-full flex justify-center items-center font-medium text-base">
                {textInsteadSearchbar}
              </div>
            )}

            <div className="flex ml-7 items-center">
              <Menu as="div" className="relative">
                <Menu.Button className="relative h-8 w-8 flex rounded-full ">
                  <img
                    alt="logo"
                    className="h-8 w-8 rounded-full object-cover"
                    src={imageSrc || "https://via.placeholder.com/68x68"} // Fallback to placeholder if no image source
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/my_profile"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          {t("your_profile")}
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/sort_listed_profiles"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          {t("shortListedProfiles")}
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      <TranslationDropdown />
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <>
                          {openLogout ? (
                            <div
                              className="bg-red-100 border mx-5 border-red-400 text-red-700 px-4 py-1 rounded flex items-center justify-between"
                              role="alert"
                            >
                              <strong
                                onClick={() => {
                                  handleLogout();
                                }}
                                className="font-semibold cursor-pointer"
                              >
                                Yes
                              </strong>
                              <strong
                                onClick={() => {
                                  setOpenLogout(false);
                                }}
                                className="font-semibold cursor-pointer"
                              >
                                No
                              </strong>
                            </div>
                          ) : null}
                          <button
                            onClick={() => {
                              setOpenLogout(true);
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                            )}
                          >
                            {t("sign_out")}
                          </button>
                        </>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            close();
                          }}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          )}
                        >
                          {t("close")}
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export function NavbarComponent2() {
  const navigate = useNavigate();

  return (
    <div as="nav" className="bg-white px-4 h-full w-full">
      <div className=" h-full w-full px-2 sm:px-6 lg:px-8 relative flex items-center justify-between">
        <div className="flex mr-7  items-center justify-center sm:items-stretch sm:justify-start">
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="flex flex-shrink-0 items-center "
          >
            <img className="h-8 w-auto" src={logo} alt="Your Company" />
          </div>
        </div>
      </div>
    </div>
  );
}
