import React from "react";

import bannerImage from "assets/coming_soon_img1.png";
import logo from "assets/logo.png";

export default function ComingSoonPage() {
  return (
    <div className="absolute w-full h-[100vh] top-0 left-0  flex items-start justify-center">
      <div class="relative overflow-hidden">
        {/* <div class="bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24"> */}
        <div class="mx-auto max-w-5xl lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
              <div class="lg:py-24">
                <div class="mt-12 w-full  items-center lg:block flex justify-center">
                  <img className=" w-24" src={logo} alt="" />
                </div>
                <h1 class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span class="block text-primary-light">Comming Soon!</span>
                  <span class="block text-black">
                    Teli Vadhu Var Parichay App
                  </span>
                </h1>
                <p class="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Embark on a journey towards finding your soulmate with Teli
                  Vadhu Var Parichay App.
                </p>
              </div>
            </div>
            <div class="mt-12  lg:block lg:w-full w-full items-center p-10 lg:p-0 flex justify-center">
              <img className="" src={bannerImage} alt="" />
            </div>
          </div>
        </div>
        <footer class="bg-white">
          <div class="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
            <nav
              class="-mx-5 -my-2 flex flex-wrap justify-center"
              aria-label="Footer"
            >
              <div class="px-5 py-2">
                <a
                  href="/terms_and_conditions"
                  class="text-base text-gray-500 hover:text-gray-900"
                >
                  Terms
                </a>
              </div>

              <div class="px-5 py-2">
                <a
                  href="/privacy_policy"
                  class="text-base text-gray-500 hover:text-gray-900"
                >
                  Privacy
                </a>
              </div>
            </nav>

            <p class="mt-8 text-center text-base text-gray-400">
              © 2024 Realce Systems. All rights reserved.
            </p>
          </div>
        </footer>
        {/* </div> */}
      </div>
    </div>
  );
}
